<template>
  <a @click.prevent="connectWallet" v-if="!ethereum.account" href="#" class="btn">Connect Wallet</a>
  <router-link :to="{name: 'Wallet'}" v-else class="account-link text-sm">
    {{ account }}
    <Blockie :address="this.ethereum.account"/>
  </router-link>
</template>

<script>
import { Ethereum } from '../js/ethereum.js';
import Blockie from './Blockie';


export default {
  name: 'ConnectButton',
  components: { Blockie },
  data() {
    return {
      ethereum: Ethereum.state
    };
  },
  methods: {
    async connectWallet() {
      await Ethereum.connect();
    }
  },
  computed: {
    account() {
      return this.ethereum.account.substr(0, 6) + '…' + this.ethereum.account.substr(38);
    }
  }
};
</script>

<style lang="scss">
.account-link {
  display: inline-flex;
  align-items: center;

  .blockie {
    @apply ml-2;

    width: 1.5em;
    display: inline-block;
    border-radius: 0.5em;
  }
}
</style>