<template>
  <nav class="navigation w-full bg-purple-black p-4 mt-0 flex items-center">
    <div class="container mx-auto flex flex-col md:flex-row items-center justify-between text-white">
      <router-link :to="{name: 'Home'}" class="flex items-center font-extrabold mb-2 md:mb-0">
        <img src="../assets/logo.png" style="width: 2em; height: 2em;" class="mr-2"/>
        ChainFaces <span class="ml-2 text-yellow-400 font-script">Arena</span>
      </router-link>
      <div class="flex p1-4 ml-4">
        <ul class="list-reset flex justify-between flex-1 md:flex-none items-center">
          <li class="mr-4" v-for="link in links">
            <router-link :to="link.href" class="transition-all">{{ link.title }}</router-link>
          </li>
        </ul>
        <a href="https://etherchain.org/tools/gasnow" target="_blank" class="gas-price hidden md:flex items-center px-2 ml-5 bg-black rounded-md text-sm justify-center" style="width: 60px;">
          <GasIcon class="mr-2"/>
          <span>{{ gasPrice }}</span>
        </a>
        <div class="ml-3 md:ml-8">
          <ConnectButton/>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import ConnectButton from './ConnectButton';
import Footer from './Footer';
import axios from 'axios';
import GasIcon from './GasIcon';

export default {
  name: 'Navigation',
  components: { GasIcon, Footer, ConnectButton },
  data () {
    return {
      gasPrice: '--',
      links: [
        {
          title: 'Mint',
          href: { name: 'Mint' }
        },
        {
          title: 'Arena',
          href: { name: 'Arena' }
        },
        {
          title: 'Graveyard',
          href: { name: 'Graveyard' }
        },
        {
          title: 'Wallet',
          href: { name: 'Wallet' }
        },
      ]
    };
  },
  mounted () {
    this.getData();
  },
  methods: {
    async getData () {
      if (this._isDestroyed) {
        return;
      }

      const gasInfo = await axios.get('https://etherchain.org/api/gasnow');
      this.gasPrice = (gasInfo.data.data.fast / 1e9).toFixed(0);

      setTimeout(this.getData.bind(this), 10000);
    }
  }
};
</script>

<style lang="scss">
.navigation {

  ul {
    a {
      @apply border-b-2 border-purple-black;
    }

    a.router-link-exact-active {
      @apply border-yellow-500;
    }

    a:hover {
      @apply text-yellow-200;
    }
  }

  .gas-price {
    svg {
      width: 0.75em;
      height: 0.75em;
    }
  }
}
</style>