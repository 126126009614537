import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import { reactive } from 'vue';

export const CF2_ABI = require('../../artifacts/contracts/ChainFaces2.sol/ChainFaces2.json').abi;

const NETWORKS = {
  1: '0x93a796b1e846567fe3577af7b7bb89f71680173a',
  // 4: '0x068F6BC16960cb8013D7D73b6e24B5823CA4Dea9',
  1337: '0xfF1271769a5fdd2BBD7d9b6760C1D3D05Ce57236'
};

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: 'aa2db798a0dd4ad5bcc31205e7c43a59'
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

function updateState (ethereum, chainId) {
  ethereum.state.chainId = parseInt(chainId);

  if (ethereum.state.chainId in NETWORKS) {
    console.log('Known chain ' + ethereum.state.chainId)
    ethereum.contract = new ethers.Contract(NETWORKS[ethereum.state.chainId], CF2_ABI, ethereum.provider);
    ethereum.state.knownChain = true;
    console.log(ethereum.contract)
  } else {
    console.log('Unknown chain ' + ethereum.state.chainId)
    ethereum.contract = null;
    ethereum.state.knownChain = false;
  }
}

export const Ethereum = {
  state: reactive({
    account: null,
    connected: false,
    loading: false,
    chainId: null,
    knownChain: false,
  }),

  web3: null,
  provider: null,
  signer: null,
  contract: null,

  async init () {
    if (window.ethereum !== undefined) {
      console.log('Injected ethereum provider found.');
      this.provider = new ethers.providers.Web3Provider(window.ethereum);

      window.ethereum.on('disconnect', () => {
        this.state.connected = false;
      });

      window.ethereum.on('connect', (info) => {
        updateState(this, info.chainId);
      });

      window.ethereum.on('chainChanged', (chainId) => {
        updateState(this, chainId);
      });

      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      updateState(this, chainId);

      this.state.connected = true;

      this.connect(false);
    }
  },

  async disconnect () {
    await web3Modal.clearCachedProvider();
    this.state.account = null;
  },

  async connect (clearCache = true) {
    this.state.loading = true;

    if (clearCache) {
      await web3Modal.clearCachedProvider();
    }

    let web3Provider = await web3Modal.connect();
    let web3 = new Web3(web3Provider);

    this.web3 = web3;

    web3.eth.extend({
      methods: [
        {
          name: "chainId",
          call: "eth_chainId",
          outputFormatter: web3.utils.hexToNumber
        }
      ]
    });

    let self = this;

    async function setup () {
      const chainId = await web3.eth.chainId();
      self.state.chainId = parseInt(chainId);

      self.provider = new ethers.providers.Web3Provider(web3Provider);
      self.signer = self.provider.getSigner();

      if (self.state.chainId in NETWORKS) {
        self.contract = new ethers.Contract(NETWORKS[self.state.chainId], CF2_ABI, self.signer);
        self.state.knownChain = true;
      }
    }

    await setup();

    try {
      const accounts = await web3.eth.getAccounts();
      this.state.account = accounts[0];
    } catch(e) {
      console.error(e)
      try {
        const accounts = await web3Provider.enable();
        this.state.account = accounts[0];
      } catch(e) {
        console.error(e)
      }
    }

    web3Provider.on('accountsChanged', (accounts) => {
      this.state.account = accounts[0];
    });

    web3Provider.on('chainChanged', (chainId) => {
      updateState(this, chainId);
      setup();
    });

    web3Provider.on('connect', (info) => {
      updateState(this, info.chainId);
      this.state.connected = true;
      setup();
    });

    web3Provider.on('disconnect', () => {
      this.state.connected = false;
    });

    this.state.connected = true;
    this.state.loading = false;
  }
};


