<template>
  <img :src="blockie" class="blockie"/>
</template>

<script>
import makeBlockie from 'ethereum-blockies-base64';


export default {
  name: 'Blockie',
  props: ['address'],
  data() {
    return {
      blockie: ''
    };
  },
  watch: {
    address: {
      immediate: true,
      handler() {
        this.blockie = makeBlockie(this.address);
      }
    }
  }
};
</script>