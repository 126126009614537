<template>
  <div class="w-full bg-gray-900 p-20 mt-0 footer">
    <div class="container mx-auto flex text-white flex-col md:flex-row">
      <div class="flex-1 text-center flex flex-col justify-center">
        <div class="font-bold text-yellow-300">
          Resources
        </div>
        <ul>
          <li><a href="https://opensea.io/collection/chainfaces-arena" target="_blank">OpenSea Collection</a></li>
          <li><a href="https://etherscan.io/address/0x93a796b1e846567fe3577af7b7bb89f71680173a" target="_blank">Contract</a></li>
        </ul>
      </div>
      <div class="flex-2 flex-grow text-center text-6xl text-gray-600 m-10">
        <span class="whitespace-nowrap">( ͡° ͜ʖ ͡°)</span><span class="ml-5 text-base">- gm</span>
      </div>
      <div class="flex-1 text-center flex flex-col justify-center">
        <div class="font-bold text-yellow-300">
          Socials
        </div>
        <ul>
          <li><a href="https://discord.gg/hnTegbMMsk" target="_blank">Discord</a></li>
          <li><a href="https://twitter.com/NateAlexNFT">Twitter</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.footer {
  box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
</style>