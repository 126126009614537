import { createRouter, createWebHashHistory } from 'vue-router';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/mint',
    name: 'Mint',
    component: () => import(/* webpackChunkName: "mint" */ '../views/Mint.vue')
  },
  {
    path: '/arena/:address?',
    name: 'Arena',
    component: () => import(/* webpackChunkName: "arena" */ '../views/Arena.vue')
  },
  {
    path: '/graveyard',
    name: 'Graveyard',
    component: () => import(/* webpackChunkName: "arena" */ '../views/Graveyard.vue')
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/Wallet.vue')
  },
  {
    path: '/owner',
    name: 'Owner',
    component: () => import(/* webpackChunkName: "owner" */ '../views/Owner.vue')
  },
  {
    path: '/face/:id',
    name: 'Face',
    component: () => import(/* webpackChunkName: "wallet" */ '../views/Face.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
