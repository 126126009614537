<template>
  <div class="w-full bg-red-800 text-center text-white font-bold text-xl p-2"
       v-if="ethereum.connected && !ethereum.knownChain">
    You are connected to an unsupported network. Please connect to Ethereum mainnet.
  </div>
  <router-view/>
</template>

<script>
import Navigation from './components/Navigation';
import { Ethereum } from './js/ethereum';

const RINKEBY_NETWORK_ID = 4;
const MAINNET_NETWORK_ID = 1;
const LOCALHOST_NETWORK_ID = 1337;

export default {
  components: { Navigation },
  data () {
    return {
      ethereum: Ethereum.state
    };
  },
  watch: {
    $route (to, from) {
      // Ignore hash only changes
      if (from.path !== to.path) {
        this.onPageChange(to);
      }
    },
  },
  mounted () {
    Ethereum.init();
  },
  methods: {
    onPageChange (to) {
      window.scrollTo(0, 0);
    },
  }
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
  font-family: 'Jura', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-pixel {
  font-family: 'Press Start 2P', sans-serif;
}

.font-script {
  font-family: 'Caveat', sans-serif;
}

.btn {
  @apply bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 rounded transition-all;

  &:disabled {
    @apply bg-gray-300 text-gray-400 cursor-not-allowed;
  }
}

.btn-red {
  @apply bg-red-800 hover:bg-red-500 text-white font-bold py-2 px-4 rounded transition-all;

  &:disabled {
    @apply bg-gray-300 text-gray-400 cursor-not-allowed;
  }
}

.btn-mint {
  @apply bg-purple-dark hover:bg-purple-light text-white font-bold py-4 px-8 rounded-xl transition-all font-bold;

  &:disabled {
    @apply bg-gray-300 text-gray-400 cursor-not-allowed;
  }
}

.btn-arena {
  @apply bg-red-800 hover:bg-red-500 text-white font-bold py-4 px-8 rounded-xl transition-all font-bold;

  &:disabled {
    @apply bg-gray-300 text-gray-400 cursor-not-allowed;
  }
}

.noty_buttons {
  display: grid;
  grid-gap: 5px;
  grid-auto-flow: column;
  font-size: 90% !important;
}

.noty_body {
  font-size: 120% !important;
}

.bg-purple-pattern {
  @apply bg-purple-dark;

  background: url("./assets/purple-bg.png") repeat;
}
</style>
