import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Noty library
import 'noty/src/noty.scss'
import 'noty/src/themes/metroui.scss'
window.Noty = require('noty')

createApp(App).use(router).mount('#app')
